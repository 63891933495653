import { IPosType, ITransaction } from "@foodi/core";
import { OrderThunks } from "@modules";
import _ from "lodash";
import { Dispatch } from "react";
import { I18n } from "react-redux-i18n";
import { decode } from "html-entities";

export interface ITransactionType {
  title: string;
  isSelected: boolean;
  type: IPosType;
}

export enum TransactionTypes {
  ORDER = "ORDER",
  REFILL = "REFILL",
}

export class TransactionsViewModel {
  transactions: ITransaction[] = [];
  tabIndex: IPosType = "OTHER";
  tabs: ITransactionType[] = [
    {
      title: I18n.t("refill.seeAll"),
      isSelected: true,
      type: "OTHER",
    },
    {
      title: I18n.t("refill.restaurant"),
      isSelected: false,
      type: "CAFETERIA",
    },
    {
      title: I18n.t("refill.refills"),
      isSelected: false,
      type: "SELF",
    },
  ];

  setTransactions(transactions: ITransaction[]) {
    this.transactions = transactions;
    this.sortTransactions();
  }

  private sortTransactions() {
    this.transactions.sort((transactionA, transactionB) => {
      const date1 = new Date(transactionA.date);
      const date2 = new Date(transactionB.date);
      const diffDays = date2.getTime() - date1.getTime();
      return diffDays;
    });
  }

  filterTransactions(transactions: any[], tabIndex: number) {
    let filteredTransactions = transactions;
    if (tabIndex === 1)
      filteredTransactions = transactions.filter(
        ({ oldBalance, newBalance }) => oldBalance.amount >= newBalance.amount
      );
    if (tabIndex === 2)
      filteredTransactions = transactions.filter(
        ({ oldBalance, newBalance }) => oldBalance.amount < newBalance.amount
      );

    this.transactions = filteredTransactions;
    this.sortTransactions();
  }

  public checkTypeOfTransactions({oldBalance, newBalance}: ITransaction) {
    const oldAmount = parseInt(oldBalance.amount || "");
    const newAmount = parseInt(newBalance.amount || "");
    if (oldAmount >= newAmount) return TransactionTypes.ORDER;
    if (oldAmount < newAmount) return TransactionTypes.REFILL;
    return '';
  }


  public getGroupedTransactions() {
    return this.transactions.reduce<Record<string, ITransaction[]>>(
      (groupTransactions, transaction) => {
        const transactionDate = new Date(transaction.date);
        const monthAndYear =
          transactionDate.getMonth() + "/" + transactionDate.getFullYear();
        const group = groupTransactions[monthAndYear] || [];
        group.push(transaction);
        groupTransactions[monthAndYear] = group;
        return groupTransactions;
      },
      {}
    );
  }

  public getInitTransactionsTabs(): ITransactionType[] {
    return this.tabs;
  }

  public getTranslatedTransactionsTabs(currentTabs: ITransactionType[]): ITransactionType[] {
    return [
      {
        ...currentTabs[0],
        title: I18n.t("refill.seeAll"),
      },
      {
        ...currentTabs[1],
        title: I18n.t("refill.restaurant"),
      },
      {
        ...currentTabs[2],
        title: I18n.t("refill.refills"),
      }]
  }

  public getTransactionsTabs(tabIndex: number): ITransactionType[] {
    const _tabs = this.tabs.map((tab, index) => {
      return { ...tab, isSelected: tabIndex === index };
    });
    return _tabs;
  }

  public getTransactionReceipt(
    dispatch: Dispatch<any>,
    idTransaction: string
  ): Promise<string> {
    //@ts-ignore
    return dispatch(
      OrderThunks.getOrderReceipt({
        idTransaction,
      })
    );
  }

  public async getHandleReceipt(
    receipt: string,
    dispatch: Dispatch<any>,
    id: string
  ) {
    let res = "";

    if (_.isEmpty(receipt)) {
      try {
        res = await this.getTransactionReceipt(dispatch, id);
      } catch (error: any) {
        res = error;
      }
      //@ts-ignore
      res = res?.cause?.error?.message || decode(atob(res), { level: "html5" });
      return res;
    }
  }
}
