import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Title13, Title16, Title18, Title22 } from "@stylesheets";
import {
  AccountCreationTemplate,
  Button,
  FloatTextInput,
  HoldingRow,
  WhiteButton,
} from "@atomic";
import { unstable_batchedUpdates } from "react-dom";
import { NavigationProp, useIsFocused, Route } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import {
  AccountActions,
  AccountScreens,
  AccountThunks,
  AuthThunks,
  Step2ViewModel,
} from "@modules";
import { I18n, setLocale } from "react-redux-i18n";
import { Colors, Spacing } from "@constants";
import { displayToastError, TestIDs } from "@utils";
import _ from "lodash";
import { Holding, SignUpCommand } from "@foodi/core";
import { GlobalState } from "@redux";
import { Information, ToolTip } from "@assets";
import { responsiveSizeWidth, useDevices } from "@hooks";
import { ErrorType } from "../../../../apollo/errorHandler";
import BadgeValidationModal from "@atomic/organisms/BadgeValidationModal";

interface IProps {
  navigation: NavigationProp<any>;
  route?: Route<any>;
}
const BADGE_NOT_FOUND_ERROR = "Badge not found or expired";

let badgeErrorMessage = "";

export const Step2: React.FC<IProps> = React.memo(({ navigation, route }) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const [isMobile] = useDevices();
  //@ts-ignore
  const token = route?.params?.accessToken;

  const Title: React.FC<any> = isMobile ? Title18 : Title22;
  const styles = useMemo(() => _styles(isMobile), [isMobile]);

  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [isHoldingsListShown, setHoldingsListShown] = useState<boolean>(false);
  const [selectedHolding, setSelectedHolding] = useState<Holding | undefined>(
    undefined
  );
  const [badgeName, setBadgeName] = useState<string>("");
  const [serialNumber, setSerialNumber] = useState<string>("");
  const [haveBadgeData, setHaveBadgeData] = useState<boolean>(false);
  const [showToolTipMessage, setShowToolTipMessage] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [isBadgeRequired, setBadgeRequired] = useState<boolean>(false);
  const [isScolapassCashSystem, setScolapassCashSystem] = useState<boolean>(
    false
  );
  const [showBadgeValidationModal, setBadgeValitationModal] = useState<boolean>(
    false
  );
  const [signupInputData, setSignupInputData] = useState({});

  const registerWithAccount = isScolapassCashSystem
    ? "account.scolapass.registerWithAccount"
    : "account.registerWithBadge";
  const registerWithoutAccount = isScolapassCashSystem
    ? "account.scolapass.registerWithoutAccount"
    : "account.registerWithoutBadge";
  const { firstName, lastName, email, password, checkBoxComm } =
    useSelector((state: GlobalState) => state.account.userInfoStep1) ?? {};

  const handleSignUp = async (skipStep: boolean) => {
    const optins = Step2ViewModel.setOptins(checkBoxComm);

    const inputData: SignUpCommand = {
      input: {
        firstName,
        lastName,
        language: "fr",
        optins,
      },
    };

    if (token) {
      inputData.input.token = token;
    } else {
      inputData.input.email = email;
      inputData.input.password = password;
    }

    if (!skipStep) {
      if (selectedHolding) {
        inputData.input.idHolding = selectedHolding?.id;
      }

      if (haveBadgeData) {
        inputData.input.guestLastName = badgeName;
        inputData.input.serialNumber = serialNumber;
      }
    }

    setSignupInputData(inputData.input);

    try {
      setLoading(true);
      const { errorMessage }: any = await dispatch(
        AuthThunks.signUp(inputData)
      );

      if (errorMessage) {
        setShowError(true);
        return;
      }
      navigation.navigate(AccountScreens.STEP_THREE_SCREEN);
    } catch (error) {
      if (error?.cause?.message.includes("Validation code needed")) {
        setBadgeValitationModal(true);
      } else {
        if (
          [
            ErrorType.BADGE_NOT_FOUND,
            ErrorType.BADGE_EXPIRED,
            ErrorType.BADGE_NOT_ENABLED,
            ErrorType.USER_NOT_ASSOCIATED_WITH_HOLDING,
            ErrorType.USER_NOT_FOUND_IN_SCOLAPASS_DB,
          ].includes(error?.cause?.message)
        ) {
          if (isScolapassCashSystem)
            badgeErrorMessage = I18n.t("account.scolapass.wrongFamilyAccount");
          else if (error?.cause?.message === ErrorType.BADGE_EXPIRED)
            badgeErrorMessage = I18n.t("account.expiredBadge");
          else if (error?.cause?.message === ErrorType.BADGE_NOT_ENABLED)
            badgeErrorMessage = I18n.t("account.notEnabledBadge");
          else badgeErrorMessage = I18n.t("account.wrongBadge");
          setShowError(true);
          return;
        }
        displayToastError(dispatch);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isFocused) {
      dispatch(AccountActions.setCurrentStepIndex(2));
    }
  }, [isFocused]);

  useEffect(() => {
    if (searchValue.length > 1 && !selectedHolding)
      getNearHoldingsDebounce(searchValue);
    else setHoldings([]);
  }, [searchValue]);

  useEffect(() => {
    setHoldingsListShown(holdings.length > 0);
  }, [holdings]);

  const getNearHoldingsDebounce = useCallback(
    _.debounce(getNearHoldings, 500),
    []
  );

  async function getNearHoldings(searchText: string) {
    const { holdingInfo }: any = await dispatch(
      AccountThunks.getNearHoldings({
        searchText: `${searchText}%`,
        email,
        withDistance: false,
        order: "name",
      })
    );

    setHoldings(holdingInfo);
  }

  const onChangeHolding = (text: string) => {
    unstable_batchedUpdates(() => {
      setSelectedHolding(undefined);
      setSearchValue(text);
      setBadgeRequired(false);
      setScolapassCashSystem(false);
      setHaveBadgeData(false);
      setSerialNumber("");
      setBadgeName("");
      setShowError(false);
    });
  };

  const onChangeBadgeNumber = (text: string) => {
    setShowError(false);
    if (isScolapassCashSystem && isNaN(+text)) {
      setShowError(true);
    }

    if (text !== "" && badgeName !== "") {
      setHaveBadgeData(true);
    } else {
      setHaveBadgeData(false);
    }
    setSerialNumber(text.trim());
  };

  const onChangeBadgeName = (text: string) => {
    setShowError(false);
    if (text !== "" && serialNumber !== "") {
      setHaveBadgeData(true);
    } else {
      setHaveBadgeData(false);
    }
    setBadgeName(text.trim());
  };

  const onSelectHolding = (_selectedHolding: Holding) => {
    unstable_batchedUpdates(() => {
      setSelectedHolding(_selectedHolding);
      setSearchValue(_selectedHolding.name?.trim() ?? "");
      setHoldingsListShown(false);
      setBadgeRequired(!!_selectedHolding?.isBadgeRequired);
      setScolapassCashSystem(!!_selectedHolding?.isScolaPassCashSystem);
    });
  };

  const userLanguage = useSelector(
    (state: GlobalState) => state.auth?.userInfo?.language || state.i18n?.locale
  );
  useEffect(() => {
    dispatch(setLocale(userLanguage));
  }, [userLanguage]);

  const checkBadgeInfo =
    (!serialNumber && !!badgeName) || (!!serialNumber && !badgeName);

  const searchError =
    searchValue?.length > 2 &&
    holdings.length === 0 &&
    selectedHolding === undefined;

  return (
    <AccountCreationTemplate navigation={navigation}>
      <View style={styles.container}>
        <View style={styles.topContainer}>
          <View style={styles.textContainer}>
            <Title isBold isBlack>
              {I18n.t("account.establishment")}
            </Title>
            <Title16>{I18n.t("account.consultServices")}</Title16>
          </View>
          <View style={styles.listContainer}>
            <View style={styles.textHoldingInputContainer}>
              <FloatTextInput
                forwardTestID={TestIDs.account.texts.holdingName}
                value={searchValue}
                onChangeValue={onChangeHolding}
                textPlaceHolder={I18n.t("account.establishmentName")}
                hasError={searchError}
                errorMessage={I18n.t("account.invalidHolding")}
              />
            </View>
            {isHoldingsListShown && (
              <ScrollView style={styles.holdingList}>
                {holdings.map((holding, index) => (
                  <HoldingRow
                    forwardTestID={`${TestIDs.account.actions.holdingRow}_${index}`}
                    key={holding.id}
                    holding={holding}
                    onSelectHolding={onSelectHolding}
                  />
                ))}
              </ScrollView>
            )}
          </View>
        </View>
        {isBadgeRequired && (
          <View>
            <View style={styles.bottomContainer}>
              <View style={styles.textContainer}>
                <View style={styles.row}>
                  <Title isBold isBlack>
                    {I18n.t(
                      isScolapassCashSystem
                        ? "account.scolapass.family"
                        : "account.badge"
                    )}
                  </Title>
                  <View style={styles.toolTipContainer}>
                    <Pressable
                      testID={TestIDs.account.actions.tooltipIcon}
                      // @ts-ignore
                      onHoverIn={() => setShowToolTipMessage(true)}
                      onHoverOut={() => setShowToolTipMessage(false)}
                      //for mobile responsive
                      onPress={() => setShowToolTipMessage(!showToolTipMessage)}
                    >
                      <ToolTip hideAlt />
                      {showToolTipMessage && (
                        <View
                          testID={TestIDs.account.views.tooltipMessage}
                          style={styles.tooltiptext}
                        >
                          <Title13 style={styles.bottomText}>
                            {I18n.t(
                              isScolapassCashSystem
                                ? "account.scolapass.toolTipMessage"
                                : "account.toolTipMessage"
                            )}
                          </Title13>
                        </View>
                      )}
                    </Pressable>
                  </View>
                </View>
                <Title16>
                  {I18n.t(
                    isScolapassCashSystem
                      ? "account.scolapass.consultInvoices"
                      : "account.refillsAndOrders"
                  )}
                </Title16>
              </View>
              <View>
                <View style={styles.textInputContainer2}>
                  <FloatTextInput
                    forwardTestID={TestIDs.account.texts.badgeNumber}
                    errorForwardTestID={TestIDs.account.views.badgeErrorMessage}
                    value={serialNumber}
                    onChangeValue={(value) => onChangeBadgeNumber(value)}
                    textPlaceHolder={I18n.t(
                      isScolapassCashSystem
                        ? "account.scolapass.familyFileNumber"
                        : "account.badgeNumber"
                    )}
                    hasError={checkBadgeInfo || showError}
                  />
                </View>
                <View style={styles.textInputContainer1}>
                  <FloatTextInput
                    forwardTestID={TestIDs.account.texts.badgeName}
                    value={badgeName}
                    onChangeValue={(value) => onChangeBadgeName(value)}
                    textPlaceHolder={I18n.t(
                      isScolapassCashSystem
                        ? "account.scolapass.lastName"
                        : "account.badgeName"
                    )}
                    hasError={checkBadgeInfo || showError}
                    errorMessage={badgeErrorMessage}
                  />
                </View>
              </View>
            </View>
            {showToolTipMessage && (
              <View
                testID={TestIDs.account.views.tooltipMessage}
                style={styles.tooltiptext}
              >
                <Title13 style={styles.bottomText}>
                  {I18n.t(
                    isScolapassCashSystem
                      ? "account.scolapass.toolTipMessage"
                      : "account.toolTipMessage"
                  )}
                </Title13>
              </View>
            )}
            <View style={styles.bottomTextContainer}>
              <View style={styles.bottomRow}>
                <View style={styles.iconContainer}>
                  <Information />
                </View>
                <Title13 style={styles.bottomText}>
                  {isMobile
                    ? I18n.t(
                        isScolapassCashSystem
                          ? "account.scolapass.authorizationThirdPartyMobile"
                          : "account.badgeAuthorizationMobile"
                      )
                    : I18n.t(
                        isScolapassCashSystem
                          ? "account.scolapass.authorizationThirdParty"
                          : "account.badgeAuthorization"
                      )}
                </Title13>
              </View>
            </View>
          </View>
        )}
      </View>
      {isBadgeRequired && (
        <Button
          forwardTestID={TestIDs.account.actions.signUpWithBadge}
          onPress={() => handleSignUp(false)}
          disabled={!haveBadgeData || !selectedHolding || showError || loading}
          label={I18n.t(
            isBadgeRequired ? registerWithAccount : "account.scolapass.register"
          )}
          loading={loading}
          styleButton={styles.firstButton}
        />
      )}
      {selectedHolding && (
        <WhiteButton
          forwardTestID={TestIDs.account.actions.signUpWithoutBadge}
          onPress={() => handleSignUp(false)}
          disabled={haveBadgeData || !selectedHolding || loading}
          label={I18n.t(
            isBadgeRequired
              ? registerWithoutAccount
              : "account.scolapass.register"
          )}
          loading={loading}
        />
      )}
      <Pressable
        testID={TestIDs.account.actions.signUpWithoutHolding}
        onPress={() => handleSignUp(true)}
      >
        <Title16 style={styles.skipText}>{I18n.t("account.skipStep2")}</Title16>
      </Pressable>
      {showBadgeValidationModal && (
        <BadgeValidationModal
          navigation={navigation}
          signupInputData={signupInputData}
          handleSignUp={handleSignUp}
          closeModal={() => setBadgeValitationModal(false)}
        />
      )}
    </AccountCreationTemplate>
  );
});

const _styles = (isMobile: boolean) =>
  StyleSheet.create({
    container: {
      flex: 1,
      marginTop: 35,
      alignItems: "center",
      width: isMobile ? "100vw" : "auto",
      zIndex: 10,
      padding: 20,
    },
    topContainer: {
      marginTop: 20,
      zIndex: 10,
    },
    bottomContainer: {
      marginTop: 20,
    },
    textContainer: {
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    toolTipContainer: {
      marginLeft: 5,
    },
    bottomText: {
      color: Colors.foodiBlack,
    },
    skipText: {
      marginTop: 20,
      textDecorationLine: "underline",
    },
    bottomTextContainer: {
      alignItems: "flex-start",
      marginBottom: 20,
      marginTop: 10,
    },
    bottomRow: {
      flexDirection: "row",
      marginBottom: 15,
    },
    listContainer: {
      marginTop: 15,
      zIndex: 10,
      backgroundColor: Colors.white,
    },
    textHoldingInputContainer: {
      justifyContent: "center",
      alignItems: "center",
    },
    textInputContainer1: {
      justifyContent: "center",
      alignItems: "center",
      zIndex: 15,
    },
    textInputContainer2: {
      justifyContent: "center",
      alignItems: "center",
    },
    textInput: {
      height: 50,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      fontSize: 16,
      zIndex: 1,
      color: Colors.foodiBlack,
      fontWeight: "400",
      borderRadius: 8,
      paddingHorizontal: 17,
      paddingTop: 15,
      paddingBottom: 14,
    },
    holdingList: {
      backgroundColor: Colors.white,
      borderWidth: 1,
      borderColor: Colors.border1,
      borderRadius: 8,
      paddingVertical: Spacing.M,
      width: isMobile ? responsiveSizeWidth(320) : 350,
      alignSelf: "center",
      position: "absolute",
      top: 65,
      maxHeight: 260,
    },
    firstButton: {
      marginBottom: 20,
      lineHeight: 22,
    },
    secondButton: {
      lineHeight: 22,
    },
    tooltiptext: {
      fontFamily: "manrope",
      justifyContent: "center",
      width: isMobile ? responsiveSizeWidth(278) : 278,
      minWidth: 278,
      height: 93,
      backgroundColor: Colors.background1,
      textAlign: "left",
      padding: 5,
      position: "absolute",
      top: isMobile ? "15%" : "18%",
      left: isMobile ? "80%" : "50%",
      marginLeft: isMobile ? responsiveSizeWidth(-270) : -139,
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
    },
    iconContainer: {
      marginRight: 5,
    },
  });
